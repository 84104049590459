import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/footer";
import Header from "../components/header";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

function Welcome() {
    const [toggleLoginOrderForm, setToggleLoginOrderForm] = useState(1);
    const navigate = useNavigate();
    const [customerId] = useState(sessionStorage.getItem('currentItem'));

    const Logout = () => {
        sessionStorage.removeItem('currentItem');
        navigate('/')
    }

    useEffect(() => {
        customerId == null && navigate('/')
    }, [customerId, navigate])

    return (
        <React.Fragment>
            <Header setToggleLoginOrderForm={setToggleLoginOrderForm} />
            <div className="align-center-everything my-5">
                <Card className="p-1">
                    <Card.Body>
                        <Card.Title className="">Welcome to the dashboard.</Card.Title>
                        <Card.Text>
                            Customer Id: <span style={{color: '#2eca8b'}}>{customerId != null && JSON.parse(customerId).CustomerID}</span>
                        </Card.Text>
                        <hr />
                        <Card.Text>If you need any assistance, please contact us via phone or chat.</Card.Text>
                        <Button onClick={Logout} variant="danger" className="w-50" style={{ fontWeight: 'bold' }}>Logout</Button>
                    </Card.Body>
                </Card>
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default Welcome;