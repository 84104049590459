import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import Table from 'react-bootstrap/Table';
import { Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";

function Preview() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    // Access the parameters (data and OID)
    const encryptedKey = decodeURIComponent(searchParams.get("data"));
    const OID = decodeURIComponent(searchParams.get("OID"));
    const [toggleLoginOrderForm, setToggleLoginOrderForm] = useState(1);
    const [orderData, setOrderData] = useState(null);
    const [apiResult, setApiResult] = useState(false);

    const ConfirmOrder = async () => {
        const url = "/api/confirmorder";

        const data = JSON.stringify({
            encryptedKey,
            OID
        });

        try {
            const response = await fetch(url, {
                method: "POST",
                body: data,
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`Response status: ${response.status}`);
            }

            const res = await response.json();

            if (res.Result) {
                setApiResult(true);
                setOrderData(res.data)
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    const GetContrmationUrl = async () => {
        const url = "/api/getconfirmationurl";

        const data = JSON.stringify({
            encryptedKey,
            OID: orderData.OrderId
        });

        try {
            const response = await fetch(url, {
                method: "POST",
                body: data,
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`Response status: ${response.status}`);
            }

            const res = await response.json();

            if (res.Result) {
                const redirectUrl = JSON.parse(res.confirmationurl);
                window.open(redirectUrl.returnUrl, '_blank');
            }
            
           
        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        const sessionEncryptedKey = JSON.parse(sessionStorage.getItem('currentItem'));
        if (encryptedKey == sessionEncryptedKey.data) {
            ConfirmOrder();
        }
        else {
            navigate('/')
        }
    }, []);

    return (
        <React.Fragment>
            <Header setToggleLoginOrderForm={setToggleLoginOrderForm} />
            <div className="align-center-everything my-5">
                <Card className=" pt-4 p-1 w-50">
                    <Table hover>
                        <thead>
                            <tr>
                                <th className="text-primary">Order Details</th>
                                <th className="text-primary"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Order ID</td>
                                <td>{orderData == null ? 'N/A' : orderData.OrderId}</td>
                            </tr>

                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Topic</td>
                                <td>{orderData == null ? 'N/A' : orderData.ProjectName}</td>
                            </tr>

                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Deadline</td>
                                <td>{orderData == null ? 'N/A' : orderData.DeadLineDescription}</td>
                            </tr>

                            <tr>
                                <td style={{ fontWeight: 'bold' }}>No. of Pages</td>
                                <td>{orderData == null ? 'N/A' : orderData.Pages}</td>
                            </tr>

                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Guidelines</td>
                                <td>{orderData == null ? 'N/A' : orderData.ProjectDescription}</td>
                            </tr>

                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Order Status</td>
                                <td>{orderData == null ? 'N/A' : orderData.OrderStatusDescription}</td>
                            </tr>
                        </tbody>
                    </Table>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        {
                            apiResult ? <Button onClick={GetContrmationUrl} className=" w-50">PROCESS TO PAYMENT</Button> : <h1>Operation failed</h1>
                        }
                    </div>
                </Card>
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default Preview;