import { useState } from "react";
import { useNavigate } from "react-router-dom";

function Header({ setToggleLoginOrderForm }) {
  const [userAuth] = useState(JSON.parse(sessionStorage.getItem('currentItem')));
  const navigate = useNavigate();

  const Logout = async () => {
    sessionStorage.removeItem('currentItem');
    navigate('/')
  }

  return (
    <div className="text-gray-700 text-base px-4 sticky w-full h-20 bg-slate-50" id="div-1">
      <div className="items-center justify-between flex w-full h-full max-w-screen-xl flex-row mx-auto" id="div-2">
        <div className="text-blue-600 flex items-center text-5xl cursor-pointer" id="div-3" >
          <div className="mr-6" id="div-4">
            <img src="https://acds3bucketlog.s3.amazonaws.com/images/edupro_web-logo.png" alt="EduProsOnline" />

          </div>
        </div>


        <div className="hidden md:flex flex-grow justify-center text-neutral-700 font-medium" id="div-5">
          <ul className="justify-center flex list-none" id="ul-1">
            <li className="list-item" id="li-1">
              <a href="#how-it-works" className="rounded-lg p-2" style={{ textDecoration: 'unset', color: '#000' }}>How it works</a>
            </li>
            <li className="list-item" id="li-2">
              <a href="#review" className="rounded-lg p-2" style={{ textDecoration: 'unset', color: '#000' }}>Reviews</a>
            </li>
            <li className="list-item" id="li-4">
              <a href="#pricing" className="rounded-lg p-2" style={{ textDecoration: 'unset', color: '#000' }}>Pricing</a>
            </li>
            <li className="list-item" id="li-5">
              <a href="#first-section" className="rounded-lg p-2" style={{ textDecoration: 'unset', color: '#000' }}>Contact Us</a>
            </li>
          </ul>
        </div>

        <a className="text-blue-600 flex flex-col items-end mr-6 font-medium hidden lg:flex" id="a-6" style={{ textDecoration: 'unset', color: '#000' }}>
          <p className="text-indigo-700 cursor-pointer text-xs mt-tollfree">Toll-Free 24/7</p>
          <div className="items-center cursor-pointer flex text-neutral-700">
            <p>+1.866.504.4611</p>
          </div>
        </a>

        {
          userAuth == null
            ?
            <div className="gap-x-2 flex font-medium" id="div-6">
              <a onClick={() => setToggleLoginOrderForm(1)} style={{ textDecoration: 'unset' }} type="button" className="text-neutral-700 bg-amber-300 flex items-center justify-center min-w-[7.5rem] min-h-[2.75rem] rounded-3xl overflow-hidden">Order Now</a>

              <a onClick={() => setToggleLoginOrderForm(2)} style={{ textDecoration: 'unset' }} type="button" className="text-neutral-700 bg-amber-300 flex items-center justify-center min-w-[7.5rem] min-h-[2.75rem] rounded-3xl overflow-hidden">Log In</a>
            </div>
            :
            <div className="gap-x-2 flex font-medium" id="div-6">
              <a onClick={() => Logout().then(() => setToggleLoginOrderForm(2))} style={{ textDecoration: 'unset' }} type="button" className="text-neutral-700 bg-amber-300 flex items-center justify-center min-w-[7.5rem] min-h-[2.75rem] rounded-3xl overflow-hidden">Logout</a>
            </div>
        }


      </div>
    </div>
  )
}

export default Header;