import Home from "../pages/Home";
import Preview from "../pages/Preview";
import Success from "../pages/Success";
import Welcome from "../pages/Welcome";
import { BrowserRouter, Routes, Route } from "react-router-dom";

export default function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/dashboard" element={<Welcome />} />
                <Route path="/preview" element={<Preview />} />
                <Route path="/success" element={<Success />} />
            </Routes>
        </BrowserRouter>
    );
}