import { useEffect, useState } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import '@splidejs/react-splide/css';
import workProcess from '../images/work-progress.png';
import reviewStar from '../images/reviews-stars.svg';
import reviewSiteJabber from '../images/reviews-sitejabber.svg';
import reviewIO from '../images/reviews-reviewsio.svg';
import plagirism from '../images/plagiarism.svg'
import guarantee from '../images/guarantee.svg'
import deadline from '../images/deadlines.svg'
import smileGreen from '../images/smile-green.svg';
import smileBlue from '../images/smile-blue.svg';
import writer1 from '../images/expert-writers-univ-item-1.svg';
import writer2 from '../images/expert-writers-univ-item-2.svg';
import writer3 from '../images/expert-writers-univ-item-3.svg';
import writer4 from '../images/expert-writers-univ-item-4.svg';
import writer5 from '../images/expert-writers-univ-item-5.svg';
import writer6 from '../images/expert-writers-univ-item-6.svg';
import privacyMan from '../images/home-privacy-man-bg.svg';
import sitejabberStars from '../images/reviews-slider-sitejabber-stars.svg';
import sitejabberIcon from '../images/reviews-sitejabber-icon-2.png';
import Header from '../components/header';
import Footer from '../components/footer';
import { Alert } from 'bootstrap';
import { AlertHeading } from 'react-bootstrap';

function Home() {
    const [openAccordion1, setOpenAccordion1] = useState(false);
    const [openAccordion2, setOpenAccordion2] = useState(false);
    const [openAccordion3, setOpenAccordion3] = useState(false);
    const [faq, setFaq] = useState(0);
    const [toggleLoginOrderForm, setToggleLoginOrderForm] = useState(1);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [country, setCountry] = useState("US");
    const [countryCode, setCountryCode] = useState("+1");
    const [phone, setPhone] = useState("");
    const [loginEmail, setLoginEmail] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const [ipAddress, setIpAddress] = useState(null);
    const navigate = useNavigate();

    async function Login() {
        const url = "/api/login ";

        const data = JSON.stringify({
            loginEmail,
            loginPassword
        });

        try {
            const response = await fetch(url, {
                method: "POST",
                body: data,
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`Response status: ${response.status}`);
            }

            const loggedIn = await response.json();

            if (loggedIn.Result) {
                const sessionData = {
                    CustomerID: loggedIn.CustomerID,
                    data: loggedIn.data
                }

                sessionStorage.setItem("currentItem", JSON.stringify(sessionData));
                navigate('/dashboard')
            }
            else {
                alert(loggedIn.Message)
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    async function SignUp() {
        const url = "/api/signup";

        const data = JSON.stringify({
            name,
            email,
            country,
            countryCode,
            phone,
            ipAddress
        });

        try {
            const response = await fetch(url, {
                method: "POST",
                body: data,
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`Response status: ${response.status}`);
            }

            const signup = await response.json();

            if (signup.Result) {
                const sessionData = {
                    CustomerID: signup.CustomerID,
                    data: signup.data
                }

                sessionStorage.setItem("currentItem", JSON.stringify(sessionData));
                navigate('/dashboard')
            }
            else {
                alert(signup.Message)
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    useEffect(() => {
        const fetchIpAddress = async () => {
            try {
                const response = await fetch('https://api.ipify.org?format=json');
                const data = await response.json();
                setIpAddress(data.ip);
            } catch (error) {
                console.error('Error fetching IP:', error);
            }
        };

        fetchIpAddress();
    }, [])

    return (
        <div className="App">
            <script async="" data-id="101341166" src="//static.getclicky.com/js"></script>

            <Header setToggleLoginOrderForm={setToggleLoginOrderForm} />

            <section id="first-section" className="text-gray-700 text-base bg-slate-50">
                <div className="pb-16 px-4 pt-10 max-w-7xl mx-auto" id="div-1" >
                    <div className="items-center justify-between relative flex flex-col md:flex-row" id="div-2">
                        <div className="flex flex-col items-center md:items-start mb-8 md:mb-0" id="div-3">
                            <h1 className="text-neutral-700 text-4xl sm:text-[3.25rem] leading-normal font-bold max-w-[35rem] mb-4 md:mb-8 ">
                                <span className="text-indigo-700 py-1 px-2 w-fit">Premium quality</span><br />
                                <span className="text-white bg-indigo-800 py-1 px-2 w-fit">paper writing service</span><br />
                                <span className="text-indigo-800 py-1 px-2 w-fit">made affordable</span>
                            </h1>

                            <div className="text-neutral-700 text-xl mb-4 md:mb-8" id="div-4">
                                Overwhelmed with writing assignments?<br />
                                Let us lift the academic burden for you!
                            </div>

                            <div className="justify-start flex w-full max-w-xs md:max-w-sm mb-4 md:mb-12 text-sm text-green-600 font-semibold pl-1">
                                <div className="bg-neutral-200 bg-no-repeat py-1 px-3 rounded flex items-center">
                                    Written by Human not by AI
                                </div>
                            </div>

                            <div className="hidden md:block">
                                <div className="text-stone-500 text-sm mb-3">
                                    With over 12,000+ reviews since 2018:
                                </div>
                                <div className="flex gap-4 text-blue-600" id="div-5">
                                    <a className="bg-white flex flex-col justify-between py-3 px-4 w-44 rounded-xl" id="a-1">
                                        <div className="flex items-center mb-1.5">
                                            <div className="mr-1">
                                                <span className="text-neutral-700 font-bold">4.9</span>/<span className="text-neutral-700">5</span>
                                            </div>
                                            <img src={reviewStar} className="w-20 h-4" />
                                        </div>
                                        <img src={reviewSiteJabber} className="h-5" />
                                    </a>

                                    <a className="bg-white flex flex-col justify-between py-3 px-4 w-44 rounded-xl" id="a-2">
                                        <div className="flex items-center mb-1.5">
                                            <div className="mr-1">
                                                <span className="text-neutral-700 font-bold">4.8</span>/<span className="text-neutral-700">5</span>
                                            </div>
                                            <img src={reviewStar} className="w-20 h-4" />
                                        </div>
                                        <img src={reviewIO} className="h-5" />
                                    </a>

                                </div>
                            </div>
                            {/* <!--
                  <img
                    src="./images/boy.png"
                    className="absolute bottom-14 left-[380px] w-80 h-80 hidden md:block"
                    id="img-1"
                  />
              --> */}
                        </div>

                        {
                            toggleLoginOrderForm == 1
                                ?
                                <div className="flex flex-col" id="div-9">
                                    <form id="contactForm" className="bg-indigo-800 relative w-80 min-h-[32.13rem] rounded-2xl p-6">
                                        <div className="text-white text-center text-xl font-bold mb-2">50% Off For a Limited Time!</div>
                                        <div className="mb-3">
                                            <label htmlFor="name" className="text-white text-sm mb-1">Your Name</label>
                                            <div className="relative">
                                                <input onChange={text => setName(text.target.value)} type="text" id="name" name="name" className="bg-white w-full h-10 border-2 border-gray-400 rounded-md px-2" placeholder="Enter your name" required="Please enter your name" />
                                            </div>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="email" className="text-white text-sm mb-1">Your Email</label>
                                            <div className="relative">
                                                <input onChange={text => setEmail(text.target.value)} type="email" id="email" name="email" className="bg-white w-full h-10 border-2 border-gray-400 rounded-md px-2" placeholder="Enter your email" required="Please enter your email" />
                                            </div>
                                        </div>
                                        <input type="hidden" id="RefrenceNo" name="RefrenceNo" value="5" />
                                        <div className="mb-3">
                                            <label htmlFor="phone_country" className="text-white text-sm mb-1">Phone Country</label>
                                            <input type="hidden" id="countryISO" name="CountryISO" value="US" />
                                            <div className="relative">
                                                <select onChange={val => {
                                                    const selectedIndex = val.target.selectedIndex;
                                                    const selectedOption = val.target.options[selectedIndex];
                                                    setCountry(selectedOption.getAttribute('data-iso'));
                                                    setCountryCode(val.target.value)
                                                }
                                                } id="phone_country" name="phone_country" required="Please select your country" className="bg-white w-full h-10 border-2 border-gray-400 rounded-md px-2">
                                                    <option value="">Select Country</option>
                                                    <option data-iso="AF" value="93">Afghanistan (+93)</option>
                                                    <option data-iso="AX" value="358">Åland Islands (+358)</option>
                                                    <option data-iso="AL" value="355">Albania (+355)</option>
                                                    <option data-iso="DZ" value="213">Algeria (+213)</option>
                                                    <option data-iso="AS" value="1684">American Samoa (+1684)</option>
                                                    <option data-iso="AD" value="376">Andorra (+376)</option>
                                                    <option data-iso="AO" value="244">Angola (+244)</option>
                                                    <option data-iso="AI" value="1264">Anguilla (+1264)</option>
                                                    <option data-iso="AG" value="1268">Antigua And Barbuda (+1268)</option>
                                                    <option data-iso="AR" value="54">Argentina (+54)</option>
                                                    <option data-iso="AM" value="374">Armenia (+374)</option>
                                                    <option data-iso="AW" value="297">Aruba (+297)</option>
                                                    <option data-iso="AU" value="61">Australia (+61)</option>
                                                    <option data-iso="AT" value="43">Austria (+43)</option>
                                                    <option data-iso="AZ" value="994">Azerbaijan (+994)</option>
                                                    <option data-iso="BS" value="1242">Bahamas (+1242)</option>
                                                    <option data-iso="BH" value="973">Bahrain (+973)</option>
                                                    <option data-iso="BD" value="880">Bangladesh (+880)</option>
                                                    <option data-iso="BB" value="1246">Barbados (+1246)</option>
                                                    <option data-iso="BY" value="375">Belarus (+375)</option>
                                                    <option data-iso="BE" value="32">Belgium (+32)</option>
                                                    <option data-iso="BZ" value="501">Belize (+501)</option>
                                                    <option data-iso="BJ" value="229">Benin (+229)</option>
                                                    <option data-iso="BM" value="1441">Bermuda (+1441)</option>
                                                    <option data-iso="BT" value="975">Bhutan (+975)</option>
                                                    <option data-iso="BO" value="591">Bolivia (+591)</option>
                                                    <option data-iso="BA" value="387">Bosnia And Herzegovina (+387)</option>
                                                    <option data-iso="BW" value="267">Botswana (+267)</option>
                                                    <option data-iso="BR" value="55">Brazil (+55)</option>
                                                    <option data-iso="IO" value="246">British Indian Ocean Territory (+246)</option>
                                                    <option data-iso="VG" value="1284">British Virgin Islands (+1284)</option>
                                                    <option data-iso="BN" value="673">Brunei Darussalam (+673)</option>
                                                    <option data-iso="BG" value="359">Bulgaria (+359)</option>
                                                    <option data-iso="BF" value="226">Burkina Faso (+226)</option>
                                                    <option data-iso="BI" value="257">Burundi (+257)</option>
                                                    <option data-iso="KH" value="855">Cambodia (+855)</option>
                                                    <option data-iso="CM" value="237">Cameroon (+237)</option>
                                                    <option data-iso="CA" value="1">Canada (+1)</option>
                                                    <option data-iso="CV" value="238">Cape Verde (+238)</option>
                                                    <option data-iso="BQ" value="599">Caribbean Netherland (+599)</option>
                                                    <option data-iso="KY" value="1345">Cayman Islands (+1345)</option>
                                                    <option data-iso="CF" value="236">Central African Republic (+236)</option>
                                                    <option data-iso="TD" value="235">Chad (+235)</option>
                                                    <option data-iso="CL" value="56">Chile (+56)</option>
                                                    <option data-iso="CN" value="86">China (+86)</option>
                                                    <option data-iso="CX" value="61">Christmas Island (+61)</option>
                                                    <option data-iso="CC" value="61">Cocos (Keeling) Islands (+61)</option>
                                                    <option data-iso="CO" value="57">Colombia (+57)</option>
                                                    <option data-iso="KM" value="269">Comoros (+269)</option>
                                                    <option data-iso="CG" value="242">Congo (+242)</option>
                                                    <option data-iso="CD" value="45">Congo (DRC) (+243)</option>
                                                    <option data-iso="CK" value="682">Cook Islands (+682)</option>
                                                    <option data-iso="CR" value="506">Costa Rica (+506)</option>
                                                    <option data-iso="CI" value="225">Côte d'Ivoire (+225)</option>
                                                    <option data-iso="HR" value="385">Croatia (+385)</option>
                                                    <option data-iso="CU" value="53">Cuba (+53)</option>
                                                    <option data-iso="CW" value="599">Curaçao (+599)</option>
                                                    <option data-iso="CY" value="357">Cyprus (+357)</option>
                                                    <option data-iso="CZ" value="420">Czech Republic (+420)</option>
                                                    <option data-iso="DK" value="45">Denmark (+45)</option>
                                                    <option data-iso="DJ" value="253">Djibouti (+253)</option>
                                                    <option data-iso="DM" value="1767">Dominica (+1767)</option>
                                                    <option data-iso="DO" value="1809">Dominican Republic (+1809)</option>
                                                    <option data-iso="DO" value="1829">Dominican Republic (+1829)</option>
                                                    <option data-iso="DO" value="1849">Dominican Republic (+1849)</option>
                                                    <option data-iso="TL" value="670">East Timor (+670)</option>
                                                    <option data-iso="EC" value="593">Ecuador (+593)</option>
                                                    <option data-iso="EG" value="20">Egypt (+20)</option>
                                                    <option data-iso="SV" value="503">El Salvador (+503)</option>
                                                    <option data-iso="GQ" value="240">Equatorial Guinea (+240)</option>
                                                    <option data-iso="ER" value="291">Eritrea (+291)</option>
                                                    <option data-iso="EE" value="372">Estonia (+372)</option>
                                                    <option data-iso="ET" value="251">Ethiopia (+251)</option>
                                                    <option data-iso="FK" value="500">Falkland Islands (Malvinas) (+500)</option>
                                                    <option data-iso="FO" value="298">Faroe Islands (+298)</option>
                                                    <option data-iso="FJ" value="679">Fiji (+679)</option>
                                                    <option data-iso="FI" value="358">Finland (+358)</option>
                                                    <option data-iso="FR" value="33">France (+33)</option>
                                                    <option data-iso="GF" value="594">French Guiana (+594)</option>
                                                    <option data-iso="PF" value="689">French Polynesia (+689)</option>
                                                    <option data-iso="GA" value="241">Gabon (+241)</option>
                                                    <option data-iso="GM" value="220">Gambia (+220)</option>
                                                    <option data-iso="GE" value="995">Georgia (+995)</option>
                                                    <option data-iso="DE" value="49">Germany (+49)</option>
                                                    <option data-iso="GH" value="233">Ghana (+233)</option>
                                                    <option data-iso="GI" value="350">Gibraltar (+350)</option>
                                                    <option data-iso="GR" value="30">Greece (+30)</option>
                                                    <option data-iso="GL" value="299">Greenland (+299)</option>
                                                    <option data-iso="GD" value="1473">Grenada (+1473)</option>
                                                    <option data-iso="GP" value="590">Guadeloupe (+590)</option>
                                                    <option data-iso="GU" value="1671">Guam (+1671)</option>
                                                    <option data-iso="GT" value="502">Guatemala (+502)</option>
                                                    <option data-iso="GG" value="44">Guernsey (+44)</option>
                                                    <option data-iso="GN" value="224">Guinea (+224)</option>
                                                    <option data-iso="GW" value="245">Guinea-Bissau (+245)</option>
                                                    <option data-iso="GY" value="592">Guyana (+592)</option>
                                                    <option data-iso="HT" value="509">Haiti (+509)</option>
                                                    <option data-iso="HN" value="504">Honduras (+504)</option>
                                                    <option data-iso="HK" value="852">Hong Kong (+852)</option>
                                                    <option data-iso="HU" value="36">Hungary (+36)</option>
                                                    <option data-iso="IS" value="354">Iceland (+354)</option>
                                                    <option data-iso="IN" value="91">India (+91)</option>
                                                    <option data-iso="ID" value="62">Indonesia (+62)</option>
                                                    <option data-iso="IR" value="98">Iran (+98)</option>
                                                    <option data-iso="IQ" value="964">Iraq (+964)</option>
                                                    <option data-iso="IE" value="353">Ireland (+353)</option>
                                                    <option data-iso="IM" value="44">Isle of Man (+44)</option>
                                                    <option data-iso="IL" value="972">Israel (+972)</option>
                                                    <option data-iso="IT" value="39">Italy (+39)</option>
                                                    <option data-iso="JM" value="1876">Jamaica (+1876)</option>
                                                    <option data-iso="JP" value="81">Japan (+81)</option>
                                                    <option data-iso="JE" value="44">Jersey (+44)</option>
                                                    <option data-iso="JO" value="962">Jordan (+962)</option>
                                                    <option data-iso="KZ" value="7">Kazakhstan (+7)</option>
                                                    <option data-iso="KZ" value="77">Kazakhstan (+77)</option>
                                                    <option data-iso="KZ" value="76">Kazakhstan (+76)</option>
                                                    <option data-iso="KE" value="254">Kenya (+254)</option>
                                                    <option data-iso="KI" value="686">Kiribati (+686)</option>
                                                    <option data-iso="XK" value="965">Kosovo (+383)</option>
                                                    <option data-iso="KW" value="965">Kuwait (+965)</option>
                                                    <option data-iso="KG" value="996">Kyrgyzstan (+996)</option>
                                                    <option data-iso="LA" value="856">Lao PDR (+856)</option>
                                                    <option data-iso="LV" value="371">Latvia (+371)</option>
                                                    <option data-iso="LB" value="961">Lebanon (+961)</option>
                                                    <option data-iso="LS" value="266">Lesotho (+266)</option>
                                                    <option data-iso="LR" value="231">Liberia (+231)</option>
                                                    <option data-iso="LY" value="218">Libyan Arab Jamahiriya (+218)</option>
                                                    <option data-iso="LI" value="423">Liechtenstein (+423)</option>
                                                    <option data-iso="LT" value="370">Lithuania (+370)</option>
                                                    <option data-iso="LU" value="352">Luxembourg (+352)</option>
                                                    <option data-iso="MO" value="853">Macau (+853)</option>
                                                    <option data-iso="MK" value="389">Macedonia (FYROM) (+389)</option>
                                                    <option data-iso="MG" value="261">Madagascar (+261)</option>
                                                    <option data-iso="MW" value="265">Malawi (+265)</option>
                                                    <option data-iso="MY" value="60">Malaysia (+60)</option>
                                                    <option data-iso="MV" value="960">Maldives (+960)</option>
                                                    <option data-iso="ML" value="223">Mali (+223)</option>
                                                    <option data-iso="MT" value="356">Malta (+356)</option>
                                                    <option data-iso="MH" value="692">Marshall Islands (+692)</option>
                                                    <option data-iso="MQ" value="596">Martinique (+596)</option>
                                                    <option data-iso="MR" value="222">Mauritania (+222)</option>
                                                    <option data-iso="MU" value="230">Mauritius (+230)</option>
                                                    <option data-iso="YT" value="262">Mayotte (+262)</option>
                                                    <option data-iso="MX" value="52">Mexico (+52)</option>
                                                    <option data-iso="FM" value="691">Micronesia (FSM) (+691)</option>
                                                    <option data-iso="MD" value="373">Moldova (+373)</option>
                                                    <option data-iso="MC" value="377">Monaco (+377)</option>
                                                    <option data-iso="MN" value="976">Mongolia (+976)</option>
                                                    <option data-iso="ME" value="382">Montenegro (+382)</option>
                                                    <option data-iso="MS" value="1664">Montserrat (+1664)</option>
                                                    <option data-iso="MA" value="212">Morocco (+212)</option>
                                                    <option data-iso="MZ" value="258">Mozambique (+258)</option>
                                                    <option data-iso="MM" value="95">Myanmar (+95)</option>
                                                    <option data-iso="NA" value="264">Namibia (+264)</option>
                                                    <option data-iso="NR" value="674">Nauru (+674)</option>
                                                    <option data-iso="NP" value="977">Nepal (+977)</option>
                                                    <option data-iso="NL" value="31">Netherlands (+31)</option>
                                                    <option data-iso="AN" value="599">Netherlands Antilles (+599)</option>
                                                    <option data-iso="NC" value="687">New Caledonia (+687)</option>
                                                    <option data-iso="NZ" value="64">New Zealand (+64)</option>
                                                    <option data-iso="NI" value="505">Nicaragua (+505)</option>
                                                    <option data-iso="NE" value="227">Niger (+227)</option>
                                                    <option data-iso="NG" value="234">Nigeria (+234)</option>
                                                    <option data-iso="NU" value="683">Niue (+683)</option>
                                                    <option data-iso="NF" value="672">Norfolk Island (+672)</option>
                                                    <option data-iso="KP" value="850">North Korea (+850)</option>
                                                    <option data-iso="MP" value="1670">Northern Mariana Islands (+1670)</option>
                                                    <option data-iso="NO" value="47">Norway (+47)</option>
                                                    <option data-iso="OM" value="968">Oman (+968)</option>
                                                    <option data-iso="PK" value="92">Pakistan (+92)</option>
                                                    <option data-iso="PW" value="680">Palau (+680)</option>
                                                    <option data-iso="PS" value="970">Palestine (+970)</option>
                                                    <option data-iso="PA" value="507">Panama (+507)</option>
                                                    <option data-iso="PG" value="675">Papua New Guinea (+675)</option>
                                                    <option data-iso="PY" value="595">Paraguay (+595)</option>
                                                    <option data-iso="PE" value="51">Peru (+51)</option>
                                                    <option data-iso="PH" value="63">Philippines (+63)</option>
                                                    <option data-iso="PN" value="64">Pitcairn Islands (+64)</option>
                                                    <option data-iso="PL" value="48">Poland (+48)</option>
                                                    <option data-iso="PT" value="351">Portugal (+351)</option>
                                                    <option data-iso="PR" value="1787">Puerto Rico (+1787)</option>
                                                    <option data-iso="PR" value="1939">Puerto Rico (+1939)</option>
                                                    <option data-iso="QA" value="974">Qatar (+974)</option>
                                                    <option data-iso="RE" value="262">Reunion (+262)</option>
                                                    <option data-iso="RO" value="40">Romania (+40)</option>
                                                    <option data-iso="RU" value="7">Russian Federation (+7)</option>
                                                    <option data-iso="RW" value="250">Rwanda (+250)</option>
                                                    <option data-iso="PM" value="508">Saint (PM) (+508)</option>
                                                    <option data-iso="BL" value="590">Saint Barthelemy (+590)</option>
                                                    <option data-iso="SH" value="290">Saint Helena (+290)</option>
                                                    <option data-iso="KN" value="1869">Saint Kitts And Nevis (+1869)</option>
                                                    <option data-iso="LC" value="1758">Saint Lucia (+1758)</option>
                                                    <option data-iso="SX" value="590">Saint Martin (+590)</option>
                                                    <option data-iso="VC" value="1784">Saint Vincent (SVG) (+1784)</option>
                                                    <option data-iso="WS" value="685">Samoa (+685)</option>
                                                    <option data-iso="SM" value="378">San Marino (+378)</option>
                                                    <option data-iso="ST" value="239">Sao Tome And Principe (+239)</option>
                                                    <option data-iso="SA" value="966">Saudi Arabia (+966)</option>
                                                    <option data-iso="SN" value="221">Senegal (+221)</option>
                                                    <option data-iso="CS" value="381">Serbia (+381)</option>
                                                    <option data-iso="SC" value="248">Seychelles (+248)</option>
                                                    <option data-iso="SL" value="232">Sierra Leone (+232)</option>
                                                    <option data-iso="SG" value="65">Singapore (+65)</option>
                                                    <option data-iso="SX" value="1721">Sint Maarten (+1721)</option>
                                                    <option data-iso="SK" value="421">Slovakia (+421)</option>
                                                    <option data-iso="SI" value="386">Slovenia (+386)</option>
                                                    <option data-iso="SB" value="677">Solomon Islands (+677)</option>
                                                    <option data-iso="SO" value="252">Somalia (+252)</option>
                                                    <option data-iso="ZA" value="27">South Africa (+27)</option>
                                                    <option data-iso="GS" value="500">South Georgia (+500)</option>
                                                    <option data-iso="KR" value="82">South Korea (+82)</option>
                                                    <option data-iso="SS" value="211">South Sudan (+211)</option>
                                                    <option data-iso="ES" value="34">Spain (+34)</option>
                                                    <option data-iso="LK" value="94">Sri Lanka (+94)</option>
                                                    <option data-iso="SD" value="249">Sudan (+249)</option>
                                                    <option data-iso="SR" value="597">Suriname (+597)</option>
                                                    <option data-iso="SJ" value="4779">Svalbard And Jan Mayen (+4779)</option>
                                                    <option data-iso="SZ" value="268">Swaziland (+268)</option>
                                                    <option data-iso="SE" value="46">Sweden (+46)</option>
                                                    <option data-iso="CH" value="41">Switzerland (+41)</option>
                                                    <option data-iso="SY" value="963">Syria (+963)</option>
                                                    <option data-iso="TW" value="886">Taiwan (+886)</option>
                                                    <option data-iso="TJ" value="992">Tajikistan (+992)</option>
                                                    <option data-iso="TZ" value="255">Tanzania (+255)</option>
                                                    <option data-iso="TH" value="66">Thailand (+66)</option>
                                                    <option data-iso="TG" value="228">Togo (+228)</option>
                                                    <option data-iso="TK" value="690">Tokelau (+690)</option>
                                                    <option data-iso="TO" value="676">Tonga (+676)</option>
                                                    <option data-iso="TT" value="1868">Trinidad And Tobago (+1868)</option>
                                                    <option data-iso="TN" value="216">Tunisia (+216)</option>
                                                    <option data-iso="TR" value="90">Turkey (+90)</option>
                                                    <option data-iso="TM" value="993">Turkmenistan (+993)</option>
                                                    <option data-iso="TC" value="1649">Turks And Caicos Islands (+1649)</option>
                                                    <option data-iso="TV" value="688">Tuvalu (+688)</option>
                                                    <option data-iso="VI" value="1340">U.S. Virgin Islands (+1340)</option>
                                                    <option data-iso="UG" value="256">Uganda (+256)</option>
                                                    <option data-iso="UA" value="380">Ukraine (+380)</option>
                                                    <option data-iso="AE" value="971">United Arab Emirates (+971)</option>
                                                    <option data-iso="GB" value="44">United Kingdom (+44)</option>
                                                    <option data-iso="US" value="1" selected="selected">United States (+1)</option>
                                                    <option data-iso="UY" value="598">Uruguay (+598)</option>
                                                    <option data-iso="UZ" value="998">Uzbekistan (+998)</option>
                                                    <option data-iso="VU" value="678">Vanuatu (+678)</option>
                                                    <option data-iso="VA" value="379">Vatican City (+379)</option>
                                                    <option data-iso="VE" value="58">Venezuela (+58)</option>
                                                    <option data-iso="VN" value="84">Vietnam (+84)</option>
                                                    <option data-iso="WF" value="681">Wallis And Futuna (+681)</option>
                                                    <option data-iso="WF" value="212">Western Sahara (+212)</option>
                                                    <option data-iso="YE" value="967">Yemen (+967)</option>
                                                    <option data-iso="ZM" value="260">Zambia (+260)</option>
                                                    <option data-iso="ZW" value="263">Zimbabwe (+263)</option>

                                                </select>
                                            </div>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="phone_number" className="text-white text-sm mb-1">Phone Number</label>
                                            <div className="relative">
                                                <input onChange={text => setPhone(text.target.value)} type="text" id="phone_number" name="phone_number" className="bg-white w-full h-10 border-2 border-gray-400 rounded-md px-2" placeholder="Enter phone number" required="Please enter your phone number" />
                                            </div>
                                        </div>
                                        <div className="flex w-full justify-between items-center">
                                            <p className="text-white w-60">Starting From as Low as</p>
                                            <div className="text-gray-700 flex flex-col items-end text-base gap-0.5 w-full bg-indigo-800 font-bold">
                                                <span className="text-white/80 text-sm line-through">$10</span>
                                                <span className="text-amber-300 text-2xl leading-tight text-right">$5</span>
                                            </div>
                                        </div>

                                        <div className="flex flex-col font-bold absolute bottom-6 left-1/2 transform -translate-x-1/2 items-center">
                                            <button onClick={SignUp} type='button' className="w-64 bg-amber-300 flex items-center justify-center h-11 rounded-3xl mt-3">
                                                Proceed to Order
                                            </button>
                                        </div>

                                    </form>
                                </div>
                                :
                                <div className="flex flex-col" id="div-9">
                                    <form id="contactForm" className="bg-indigo-800 relative w-80 min-h-72 rounded-2xl p-6">

                                        <div className="mb-3">
                                            <label htmlFor="email" className="text-white text-sm mb-1">Your Email</label>
                                            <div className="relative">
                                                <input onChange={text => setLoginEmail(text.target.value)} type="email" id="email" name="email" className="bg-white w-full h-10 border-2 border-gray-400 rounded-md px-2" placeholder="Enter your email" required="Please enter your email" />
                                            </div>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="password" className="text-white text-sm mb-1">Your Password</label>
                                            <div className="relative">
                                                <input onChange={text => setLoginPassword(text.target.value)} type="password" id="password" name="password" className="bg-white w-full h-10 border-2 border-gray-400 rounded-md px-2" placeholder="Enter your password" required="Please enter your password" />
                                            </div>
                                        </div>

                                        <div className="flex flex-col font-bold absolute bottom-6 left-1/2 transform -translate-x-1/2 items-center">
                                            <button onClick={Login} type='button' className="w-64 bg-amber-300 flex items-center justify-center h-11 rounded-3xl">
                                                Submit
                                            </button>
                                        </div>
                                    </form>

                                </div>
                        }

                    </div>
                </div>
            </section>

            <script type="text/javascript" async="" src="//in.getclicky.com/in.php?site_id=101341166&amp;href=%2F&amp;title=EduProsOnline%20%7C%20High%20Quality%20Paper%20Writing%20Service&amp;res=1440x900&amp;lang=en-US&amp;tz=Asia%2FKarachi&amp;tc=&amp;ck=1&amp;jsuid=38867714&amp;x=d2z5bt"></script>

            <section className="text-gray-700 text-base bg-slate-50">
                <div className="py-12 px-4 max-w-7xl mx-auto md:pt-20 md:pb-20" id="div-1">
                    <h2 className="text-neutral-700 text-[2.5rem] leading-none font-bold text-center mb-10 md:mb-12">
                        Advantages of Choosing Our Essay Service
                    </h2>

                    <div className="flex flex-col gap-10 mb-10 md:flex-row md:justify-between md:gap-5 md:mb-12">
                        <div className="bg-white py-7 px-8 border-2 border-slate-300 rounded-2xl md:w-1/3">
                            <div className="flex items-center mb-6">
                                <div className="w-14 mr-5">
                                    <img src={plagirism} className="w-14 h-14" />
                                </div>
                                <div className="text-neutral-700 text-xl font-bold max-w-xs">
                                    Plagiarism-Free Guarantee
                                </div>
                            </div>
                            <div className="text-neutral-700">
                                We maintain a strict no-plagiarism policy by creating all content
                                from scratch. Before delivering your paper, we use licensed
                                software to scan it, ensuring it is original, human-written, and
                                includes proper citations.
                            </div>
                        </div>

                        <div className="bg-white py-7 px-8 border-2 border-slate-300 rounded-2xl md:w-1/3">
                            <div className="flex items-center mb-6">
                                <div className="w-14 mr-5">
                                    <img src={guarantee} className="w-14 h-14" />
                                </div>
                                <div className="text-neutral-700 text-xl font-bold max-w-xs">
                                    100% Money Back Guarantee
                                </div>
                            </div>
                            <div className="text-neutral-700">
                                If you are not completely satisfied with the final paper, you
                                won't be charged—it's that simple. We prioritize customer
                                satisfaction above all else and offer a money-back guarantee to
                                ensure you're covered.
                            </div>
                        </div>

                        <div className="bg-white py-7 px-8 border-2 border-slate-300 rounded-2xl md:w-1/3">
                            <div className="flex items-center mb-6">
                                <div className="w-14 mr-5">
                                    <img src={deadline} className="w-14 h-14" />
                                </div>
                                <div className="text-neutral-700 text-xl font-bold max-w-xs">
                                    Adherence to Deadlines
                                </div>
                            </div>
                            <div className="text-neutral-700">
                                Our committed team works diligently to complete all orders on or
                                before the deadline, providing you with sufficient time to review
                                and submit the final paper on time.
                            </div>
                        </div>
                    </div>

                    <h3 className="text-neutral-700 text-xl font-bold mb-5">
                        Paper Instructions Review and Personalized Support
                    </h3>

                    <div className="flex flex-col gap-5 md:flex-row md:justify-between">
                        <div className="bg-white py-5 px-7 border-2 border-slate-300 rounded-2xl md:w-[49%]">
                            <div className="flex items-center justify-between mb-2">
                                <div className="text-neutral-700 font-bold">
                                    Unsure about the instructions, topic, or anything else?
                                </div>
                                <div className="w-11 h-11">
                                    <img src={smileGreen} className="w-11 h-11" />
                                </div>
                            </div>
                            <div className="text-neutral-700 text-sm">
                                No need to worry—we will review your order details and
                                instructions and reach out if there are any questions or
                                inconsistencies. We’re here to address any issues or queries that
                                may come up throughout the process.
                            </div>
                        </div>

                        <div className="bg-white py-5 px-7 border-2 border-slate-300 rounded-2xl md:w-[49%]">
                            <div className="flex items-center justify-between mb-2">
                                <div className="text-neutral-700 font-bold">
                                    Unsure about the precise page count?
                                </div>
                                <div className="w-11 h-11">
                                    <img src={smileBlue} className="w-11 h-11" />
                                </div>
                            </div>
                            <div className="text-neutral-700 text-sm">
                                Choose an estimated number of pages, and we can adjust this after
                                reviewing the details. If you select more pages than needed, we'll
                                refund the price difference to your card. For a quick
                                consultation, feel free to reach out to us via online chat.
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="how-it-works" className="text-gray-700 text-base py-20 lg:mx-40">
                <div className="px-4 max-w-7xl mx-auto">
                    <h2 className="text-[2.5rem] leading-none font-bold pb-3 text-center">
                        How Our Paper Writing Service Works?
                    </h2>
                    <p className="text-center pb-10 text-lg">
                        Left side gif to be changed with the following picture or a picture relevant with the below:
                    </p>

                    <div className="items-center justify-between flex flex-col-reverse md:flex-row" id="div-2">
                        <div className="max-w-lg w-full md:w-1/2 flex justify-center" id="div-3">
                            <img src={workProcess} />
                        </div>

                        <div className="max-w-lg w-full text-neutral-700 mt-8 md:mt-0 md:w-1/2" id="div-4">
                            <div onClick={() => setOpenAccordion1(!openAccordion1)} className="items-start cursor-pointer px-6 pt-3 flex mb-4 border-2 border-slate-300 rounded-2xl">
                                <div>
                                    <p className="flex items-center text-xl leading-7 font-medium">
                                        <span className="flex items-center justify-center w-9 h-9 border-2 border-slate-300 rounded-full">1</span>
                                        <span className="pl-5">Submit your order</span>
                                    </p>

                                    {
                                        openAccordion1 &&
                                        <p id="content-1">
                                            Complete the order form with just a few clicks. Provide specific instructions and detailed requirements to ensure we cover all important aspects.
                                        </p>
                                    }
                                </div>
                            </div>

                            <div onClick={() => setOpenAccordion2(!openAccordion2)} className="items-start cursor-pointer px-6 pt-3 flex mb-4 border-2 border-slate-300 rounded-2xl">
                                <div>
                                    <p className="flex items-center text-xl leading-7 font-medium">
                                        <span className="flex items-center justify-center w-9 h-9 border-2 border-slate-300 rounded-full">2</span>
                                        <span className="pl-5">Communicate with Your Writer</span>
                                    </p>

                                    {
                                        openAccordion2 &&
                                        <p id="content-2">
                                            Discuss the details of the task with your writer to ensure alignment. You can ask questions, consider their suggestions, provide additional materials, or simply relax while they complete the work for you.
                                        </p>
                                    }

                                </div>
                            </div>

                            <div onClick={() => setOpenAccordion3(!openAccordion3)} className="items-start cursor-pointer px-6 pt-3 flex mb-4 border-2 border-slate-300 rounded-2xl">
                                <div>
                                    <p className="flex items-center text-xl leading-7 font-medium">
                                        <span className="flex items-center justify-center w-9 h-9 border-2 border-slate-300 rounded-full">3</span>
                                        <span className="pl-5">Receive your paper</span>
                                    </p>

                                    {
                                        openAccordion3 &&
                                        <p id="content-3">
                                            We will email you when your paper is ready. Additionally, you’ll see a status update in your student area and will be able to download it from there.
                                        </p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="justify-center flex mt-9 text-neutral-700 font-medium" id="div-11">
                        <form id="orderForm2">
                            <a type="submit" className="bg-amber-300 flex items-center justify-center w-56 h-12 rounded-3xl" href="#first-section" style={{ textDecoration: 'unset', color: '#000' }}>Place an Order now</a>
                        </form>
                    </div>
                </div>
            </section>

            <section className="text-gray-700 text-base py-20" id="section-1">
                <div className="px-4 max-w-7xl mx-auto" id="div-1">
                    <div className="flex flex-wrap items-start justify-around gap-4">
                        <div className="flex lg:w-auto w-full flex-col items-center justify-center py-2 pt-4 border-2 border-slate-300 rounded-2xl" style={{ width: '22%' }}>
                            <p className="text-indigo-800 text-[2rem] leading-none font-bold ">
                                1200
                            </p>
                            <p className="text-neutral-700 text-center relative">
                                Writers Available
                                <span className="absolute right-4 top-1 w-4 h-4 bg-no-repeat bg-cover opacity-80"></span>
                            </p>
                        </div>

                        <div className="flex lg:w-auto w-full flex-col items-center justify-center py-2 pt-4 border-2 border-slate-300 rounded-2xl" style={{ width: '22%' }}>
                            <p className="text-indigo-800 text-[2rem] leading-none font-bold">
                                45K+
                            </p>
                            <p className="text-neutral-700 text-center">Orders completed</p>
                        </div>

                        <div className="flex lg:w-auto w-full flex-col items-center justify-center py-2 pt-4 border-2 border-slate-300 rounded-2xl" style={{ width: '22%' }}>
                            <p className="text-indigo-800 text-[2rem] leading-none font-bold">
                                20 s
                            </p>
                            <p className="text-neutral-700 text-center">Average response time</p>
                        </div>

                        <div className="flex lg:w-auto w-full flex-col items-center justify-center py-2 pt-4 border-2 border-slate-300 rounded-2xl" style={{ width: '22%' }}>
                            <p className="text-indigo-800 text-[2rem] leading-none font-bold">
                                99.95%
                            </p>
                            <p className="text-neutral-700 text-center">Orders delivered on time</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="text-gray-700 text-base pt-20 bg-slate-50" id="section-1">
                <div className="px-4 max-w-7xl mx-auto text-neutral-700" id="div-1">
                    <div>
                        <div className="justify-between pb-20 flex flex-col lg:flex-row" id="div-2">
                            <div className="max-w-xl mb-12 lg:mb-0" id="div-3">
                                <h3 className="text-[2.5rem] leading-none font-bold pb-7" id="h3-1">
                                    Proficient Writers and Editors
                                </h3>
                                <p className="text-lg pb-6" id="p-1">
                                    We collaborate with professional writers who have undergone a
                                    rigorous application process and have verified academic
                                    backgrounds.
                                </p>
                                <p className="text-lg pb-6" id="p-2">
                                    To meet our clients' needs, we provide writers from leading U.S.
                                    and U.K. universities, as well as ESL writers with excellent
                                    command of both written and spoken English.
                                </p>
                            </div>

                            <div className="max-w-lg" id="div-4">
                                <div className="bg-no-repeat bg-contain px-8 pt-6 h-72">
                                    <p className="text-[1.38rem] leading-7 font-medium pb-3.5" id="p-3">
                                        Accurate Expert Matching
                                    </p>
                                    We don't distribute orders to all writers. Instead, we
                                    meticulously analyze the instructions and match each order with
                                    the most qualified expert who has a demonstrated history of
                                    achieving high standards in similar tasks.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-slate-100 py-6" id="div-6">
                    <div className="px-4 max-w-7xl mx-auto" id="div-7">
                        <div className="items-center justify-between flex flex-wrap gap-0">
                            <div className="w-1/6">
                                <img src={writer1} className="w-40 h-auto" />
                            </div>
                            <div className="w-1/6">
                                <img src={writer2} className="w-40 h-auto" />
                            </div>
                            <div className="w-1/6">
                                <img src={writer3} className="w-40 h-auto" />
                            </div>
                            <div className="w-1/6">
                                <img src={writer4} className="w-40 h-auto" />
                            </div>
                            <div className="w-1/6">
                                <img src={writer5} className="w-40 h-auto" />
                            </div>
                            <div className="w-1/6">
                                <img src={writer6} className="w-40 h-auto" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="text-gray-700 text-base py-20 bg-slate-50" id="section-1">
                <div className="px-4 max-w-7xl mx-auto" id="div-1">
                    <div className="text-4xl font-bold">
                        <h1 className="text-center" style={{ fontWeight: 'bold', fontSize: 36 }}>We Safeguard Your Privacy</h1>
                    </div>

                    <div className="text-neutral-700 items-center justify-between pt-14 flex flex-col lg:flex-row" id="div-2">
                        <div className="justify-center flex w-full lg:w-1/3 hidden lg:flex" id="div-3">
                            <img src={privacyMan} className="w-72 h-auto max-w-full" />
                        </div>

                        <div className="w-full lg:w-2/3" id="div-4">
                            <div className="bg-white py-3 px-8 mb-3 border-2 border-slate-300 rounded-2xl" id="div-5">
                                <div>
                                    <div className="flex items-center">
                                        <img className="w-8 h-8 mb-3" src="https://cdn-icons-png.flaticon.com/512/1691/1691925.png" />
                                        <p className="text-xl leading-7 font-medium pb-3 pl-3" id="p-1">
                                            Confidential Communication
                                        </p>
                                    </div>
                                </div>
                                <p>
                                    We provide anonymous communication between clients and writers,
                                    ensuring privacy for both parties.
                                </p>
                            </div>

                            <div className="bg-white py-3 px-8 mb-3 border-2 border-slate-300 rounded-2xl" id="div-6">
                                <div className="flex items-center">
                                    <img className="w-8 h-8 mb-3" src="https://cdn-icons-png.flaticon.com/512/4289/4289742.png" />
                                    <p className="text-xl leading-7 font-medium pb-3 pl-3" id="p-2">
                                        Safe and Secure Payments
                                    </p>
                                </div>
                                <p>
                                    We do not collect or store any payment information. Our order
                                    form is secured with SSL 256-bit encryption.
                                </p>
                            </div>

                            <div className="bg-white py-3 px-8 border-2 border-slate-300 rounded-2xl" id="div-7">
                                <div className="flex items-center">
                                    <img className="w-8 h-8 mb-3" src="https://cdn-icons-png.flaticon.com/512/6772/6772402.png" />
                                    <p className="text-xl leading-7 font-medium pb-3 pl-3" id="p-3">
                                        Protection of Personal Data
                                    </p>
                                </div>
                                <p>
                                    Your personal information is kept private and secure in
                                    accordance with our thorough privacy policy. We adhere to all
                                    regulations strictly.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="pricing" className="text-gray-700 text-base py-20">
                <div className="px-4 max-w-7xl mx-auto">
                    <div>
                        <div className="pb-10 text-center">
                            <h3 className="text-4xl font-bold pb-5">
                                Premium Features Included at No Extra Cost
                            </h3>
                            <p className="text-xl">Included with Every Order</p>
                        </div>

                        <div className="flex flex-wrap justify-center gap-4">
                            <div className="w-44 border-2 border-slate-300 rounded-2xl overflow-hidden">
                                <p className="flex items-center justify-center h-20 font-medium">
                                    Title Page
                                </p>
                                <div className="bg-slate-100 flex items-center justify-between py-3.5 px-3 pt-prices">
                                    <p className="text-stone-500">
                                        for <span className="line-through">$15.00</span>
                                    </p>
                                    <i className="fa-solid fa-circle-check pb-check-icon"></i>

                                    <p className="text-indigo-800 text-sm font-medium uppercase">
                                        Free
                                    </p>
                                </div>
                            </div>

                            <div className="w-44 border-2 border-slate-300 rounded-2xl overflow-hidden">
                                <p className="flex items-center justify-center h-20 font-medium">
                                    Bibliography Page
                                </p>
                                <div className="bg-slate-100 flex items-center justify-between py-3.5 px-3 pt-prices">
                                    <p className="text-stone-500">
                                        for <span className="line-through">$20.00</span>
                                    </p>
                                    <i className="fa-solid fa-circle-check pb-check-icon"></i>
                                    <p className="text-indigo-800 text-sm font-medium uppercase">
                                        Free
                                    </p>
                                </div>
                            </div>

                            <div className="w-44 border-2 border-slate-300 rounded-2xl overflow-hidden">
                                <p className="flex items-center justify-center h-20 font-medium">
                                    Outline
                                </p>
                                <div className="bg-slate-100 flex items-center justify-between py-3.5 px-3 pt-prices">
                                    <p className="text-stone-500">
                                        for <span className="line-through">$15.00</span>
                                    </p>
                                    <i className="fa-solid fa-circle-check pb-check-icon"></i>
                                    <p className="text-indigo-800 text-sm font-medium uppercase">
                                        Free
                                    </p>
                                </div>
                            </div>

                            <div className="w-44 border-2 border-slate-300 rounded-2xl overflow-hidden">
                                <p className="flex items-center justify-center h-20 font-medium">
                                    Originality Check
                                </p>
                                <div className="bg-slate-100 flex items-center justify-between py-3.5 px-3 pt-prices">
                                    <p className="text-stone-500">
                                        for <span className="line-through">$25.00</span>
                                    </p>
                                    <i className="fa-solid fa-circle-check pb-check-icon"></i>
                                    <p className="text-indigo-800 text-sm font-medium uppercase">
                                        Free
                                    </p>
                                </div>
                            </div>

                            <div className="w-44 border-2 border-slate-300 rounded-2xl overflow-hidden">
                                <p className="flex items-center justify-center h-20 font-medium">
                                    Unlimited Edits
                                </p>
                                <div className="bg-slate-100 flex items-center justify-between py-3.5 px-3 pt-prices">
                                    <p className="text-stone-500">
                                        for <span className="line-through">$30.00</span>
                                    </p>
                                    <i className="fa-solid fa-circle-check pb-check-icon"></i>
                                    <p className="text-indigo-800 text-sm font-medium uppercase">
                                        Free
                                    </p>
                                </div>
                            </div>

                            <div className="w-44 border-2 border-slate-300 rounded-2xl overflow-hidden">
                                <p className="flex items-center justify-center h-20 font-medium">
                                    Formatting
                                </p>
                                <div className="bg-slate-100 flex items-center justify-between py-3.5 px-3 pt-prices">
                                    <p className="text-stone-500">
                                        for <span className="line-through">$25.00</span>
                                    </p>
                                    <i className="fa-solid fa-circle-check pb-check-icon"></i>
                                    <p className="text-indigo-800 text-sm font-medium uppercase">
                                        Free
                                    </p>
                                </div>
                            </div>
                        </div>

                        <p className="text-xl pb-10 pt-9 text-center">
                            Get the following custom features for
                            <span className="line-through">$130.00</span>
                            <span className="text-indigo-800 font-bold pl-1">Free!</span>
                        </p>

                        <div className="flex justify-center">
                            <form id="orderForm3">
                                <a type="submit" className="bg-amber-300 flex items-center justify-center w-56 h-12 rounded-3xl text-neutral-700 font-medium" href="#first-section" style={{ textDecoration: 'unset', color: '#000' }}>Order now</a>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <section id="review" className="text-gray-700 text-base py-20 bg-slate-50">
                <div className="px-4 max-w-7xl mx-auto">
                    <h2 className="text-neutral-700 text-4xl leading-none font-bold text-center mb-10">
                        Verified Reviews From Independent Platforms
                    </h2>

                    <Splide options={{
                        type: 'loop',
                        perPage: 2, // Display two slides per page (two columns)
                        perMove: 1,
                        gap: '1rem', // Add some gap between slides
                        breakpoints: {
                            640: {
                                perPage: 1, // On smaller screens, show one slide per page
                            },
                        },
                    }}>
                        <SplideSlide>
                            <li className="splide__slide splide__slide--clone" id="additional-review-slider-clone01" role="tabpanel" aria-roledescription="slide" aria-label="3 of 3" aria-hidden="true">
                                <div className="bg-white py-8 px-6 border-2 border-slate-300 border-solid rounded-2xl min-w-[300px] mx-1">
                                    <div className="flex items-center justify-between mb-5">
                                        <div>
                                            <img className="h-5" src={sitejabberStars} />
                                        </div>
                                        <div className="text-blue-900 text-xs flex items-center">Verified site experience</div>
                                    </div>
                                    <div className="text-neutral-700 text-xl font-bold mb-3">Overall, excellent!</div>
                                    <div className="text-neutral-700 mb-5">
                                        A huge thank you to the team at EduProsOnline, especially to the fantastic manager who excels at her job. I recommend this site to everyone. The paper was delivered with high quality and even two days ahead of schedule. It’s truly an outstanding service. Highly recommended! Thank you so much!
                                    </div>
                                    <div className="flex items-center">
                                        <div className="w-10 h-10 mr-4">
                                            <img className="w-full h-full" src={sitejabberIcon} />
                                        </div>
                                        <div>
                                            <div className="text-neutral-700 font-bold">User 33324</div>
                                            <div className="text-neutral-400 flex items-center gap-x-1 flex-wrap">
                                                Order 52221XXXX | USA | Sep 10,2024
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </li>
                        </SplideSlide>

                        <SplideSlide>
                            <li className="splide__slide splide__slide--clone is-active" id="additional-review-slider-clone02" role="tabpanel" aria-roledescription="slide" aria-label="1 of 3" aria-hidden="true">
                                <div className="bg-white py-8 px-6 border-2 border-slate-300 border-solid rounded-2xl min-w-[300px] mx-1">
                                    <div className="flex items-center justify-between mb-5">
                                        <div>
                                            <img className="h-5" src={sitejabberStars} />
                                        </div>
                                        <div className="text-blue-900 text-xs flex items-center">Verified site experience</div>
                                    </div>
                                    <div className="text-neutral-700 text-xl font-bold mb-3">EduProsOnline is the ideal solution.</div>
                                    <div className="text-neutral-700 mb-5">
                                        EduProsOnline is the perfect solution for academic assistance, helping you save valuable time to focus on other tasks, such as schoolwork or ensuring you don't miss out on important work hours.
                                    </div>
                                    <div className="flex items-center"></div>
                                    <div className="w-10 h-10 mr-4">
                                        <img className="w-full h-full" src={sitejabberIcon} />
                                    </div>
                                    <div>
                                        <div className="text-neutral-700 font-bold">User ID 12241</div>
                                        <div className="text-neutral-400 flex items-center gap-x-1 flex-wrap">
                                            Order 52221XXXX | USA | Aug 23, 2023
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </SplideSlide>

                        <SplideSlide>
                            <li className="splide__slide splide__slide--clone" id="additional-review-slider-clone03" role="tabpanel" aria-roledescription="slide" aria-label="2 of 3" aria-hidden="true">
                                <div className="bg-white py-8 px-6 border-2 border-slate-300 border-solid rounded-2xl min-w-[300px] mx-1">
                                    <div className="flex items-center justify-between mb-5">
                                        <div>
                                            <img className="h-5" src={sitejabberStars} />
                                        </div>
                                        <div className="text-blue-900 text-xs flex items-center">Verified site experience</div>
                                    </div>
                                    <div className="text-neutral-700 text-xl font-bold mb-3">It was extremely well written.</div>
                                    <div className="text-neutral-700 mb-5">
                                        They assisted me when I got stuck after writing the first paragraph of my essay. Unsure of how to proceed, I turned to Google for help. I’m thrilled with my decision to use this company—they truly excelled! They continued working until my essay was perfected.
                                    </div>
                                    <div className="flex items-center"></div>
                                    <div className="w-10 h-10 mr-4">
                                        <img className="w-full h-full" src={sitejabberIcon} />
                                    </div>
                                    <div>
                                        <div className="text-neutral-700 font-bold">User ID 22344</div>
                                        <div className="text-neutral-400 flex items-center gap-x-1 flex-wrap">
                                            Order 52221XXXX | USA | Jul 22, 2024
                                        </div>
                                    </div>
                                </div>
                            </li>

                        </SplideSlide>

                        <SplideSlide>
                            <li className="splide__slide splide__slide--clone is-prev" id="additional-review-slider-clone04" role="tabpanel" aria-roledescription="slide" aria-label="3 of 3" aria-hidden="true">
                                <div className="bg-white py-8 px-6 border-2 border-slate-300 border-solid rounded-2xl min-w-[300px] mx-1">
                                    <div className="flex items-center justify-between mb-5">
                                        <div>
                                            <img className="h-5" src={sitejabberStars} />
                                        </div>
                                        <div className="text-blue-900 text-xs flex items-center">Verified site experience</div>
                                    </div>
                                    <div className="text-neutral-700 text-xl font-bold mb-3">Overall, excellent!</div>
                                    <div className="text-neutral-700 mb-5">
                                        A huge thank you to the team at EduProsOnline, especially to the fantastic manager who excels at her job. I recommend this site to everyone. The paper was delivered with high quality and even two days ahead of schedule. It’s truly an outstanding service. Highly recommended! Thank you so much!
                                    </div>
                                    <div className="flex items-center">
                                        <div className="w-10 h-10 mr-4">
                                            <img className="w-full h-full" src={sitejabberIcon} />
                                        </div>
                                        <div>
                                            <div className="text-neutral-700 font-bold">User 33324</div>
                                            <div className="text-neutral-400 flex items-center gap-x-1 flex-wrap">
                                                Order 52221XXXX | USA | Sep 10,2024
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </li>
                        </SplideSlide>

                        <SplideSlide>
                            <li className="splide__slide is-active is-visible" id="additional-review-slider-slide01" role="tabpanel" aria-roledescription="slide" aria-label="1 of 3">
                                <div className="bg-white py-8 px-6 border-2 border-slate-300 border-solid rounded-2xl min-w-[300px] mx-1">
                                    <div className="flex items-center justify-between mb-5">
                                        <div>
                                            <img className="h-5" src={sitejabberStars} />
                                        </div>
                                        <div className="text-blue-900 text-xs flex items-center">Verified site experience</div>
                                    </div>
                                    <div className="text-neutral-700 text-xl font-bold mb-3">EduProsOnline is the ideal solution.</div>
                                    <div className="text-neutral-700 mb-5">
                                        EduProsOnline is the perfect solution for academic assistance, helping you save valuable time to focus on other tasks, such as schoolwork or ensuring you don't miss out on important work hours.
                                    </div>
                                    <div className="flex items-center"></div>
                                    <div className="w-10 h-10 mr-4">
                                        <img className="w-full h-full" src={sitejabberIcon} />
                                    </div>
                                    <div>
                                        <div className="text-neutral-700 font-bold">User ID 12241</div>
                                        <div className="text-neutral-400 flex items-center gap-x-1 flex-wrap">
                                            Order 52221XXXX | USA | Aug 23, 2023
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </SplideSlide>

                        <SplideSlide>
                            <li className="splide__slide is-visible is-next" id="additional-review-slider-slide02" role="tabpanel" aria-roledescription="slide" aria-label="2 of 3">
                                <div className="bg-white py-8 px-6 border-2 border-slate-300 border-solid rounded-2xl min-w-[300px] mx-1">
                                    <div className="flex items-center justify-between mb-5">
                                        <div>
                                            <img className="h-5" src={sitejabberStars} />
                                        </div>
                                        <div className="text-blue-900 text-xs flex items-center">Verified site experience</div>
                                    </div>
                                    <div className="text-neutral-700 text-xl font-bold mb-3">It was extremely well written.</div>
                                    <div className="text-neutral-700 mb-5">
                                        They assisted me when I got stuck after writing the first paragraph of my essay. Unsure of how to proceed, I turned to Google for help. I’m thrilled with my decision to use this company—they truly excelled! They continued working until my essay was perfected.
                                    </div>
                                    <div className="flex items-center"></div>
                                    <div className="w-10 h-10 mr-4">
                                        <img className="w-full h-full" src={sitejabberIcon} />
                                    </div>
                                    <div>
                                        <div className="text-neutral-700 font-bold">User ID 22344</div>
                                        <div className="text-neutral-400 flex items-center gap-x-1 flex-wrap">
                                            Order 52221XXXX | USA | Jul 22, 2024
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </SplideSlide>

                        <SplideSlide>
                            <li className="splide__slide" id="additional-review-slider-slide03" role="tabpanel" aria-roledescription="slide" aria-label="3 of 3" aria-hidden="true">
                                <div className="bg-white py-8 px-6 border-2 border-slate-300 border-solid rounded-2xl min-w-[300px] mx-1">
                                    <div className="flex items-center justify-between mb-5">
                                        <div>
                                            <img className="h-5" src={sitejabberStars} />
                                        </div>
                                        <div className="text-blue-900 text-xs flex items-center">Verified site experience</div>
                                    </div>
                                    <div className="text-neutral-700 text-xl font-bold mb-3">Overall, excellent!</div>
                                    <div className="text-neutral-700 mb-5">
                                        A huge thank you to the team at EduProsOnline, especially to the fantastic manager who excels at her job. I recommend this site to everyone. The paper was delivered with high quality and even two days ahead of schedule. It’s truly an outstanding service. Highly recommended! Thank you so much!
                                    </div>
                                    <div className="flex items-center">
                                        <div className="w-10 h-10 mr-4">
                                            <img className="w-full h-full" src={sitejabberIcon} />
                                        </div>
                                        <div>
                                            <div className="text-neutral-700 font-bold">User 33324</div>
                                            <div className="text-neutral-400 flex items-center gap-x-1 flex-wrap">
                                                Order 52221XXXX | USA | Sep 10,2024
                                            </div>
                                        </div>

                                    </div>
                                </div></li>
                        </SplideSlide>

                        <SplideSlide>
                            <li className="splide__slide splide__slide--clone is-active" id="additional-review-slider-clone05" role="tabpanel" aria-roledescription="slide" aria-label="1 of 3" aria-hidden="true">
                                <div className="bg-white py-8 px-6 border-2 border-slate-300 border-solid rounded-2xl min-w-[300px] mx-1">
                                    <div className="flex items-center justify-between mb-5">
                                        <div>
                                            <img className="h-5" src={sitejabberStars} />
                                        </div>
                                        <div className="text-blue-900 text-xs flex items-center">Verified site experience</div>
                                    </div>
                                    <div className="text-neutral-700 text-xl font-bold mb-3">EduProsOnline is the ideal solution.</div>
                                    <div className="text-neutral-700 mb-5">
                                        EduProsOnline is the perfect solution for academic assistance, helping you save valuable time to focus on other tasks, such as schoolwork or ensuring you don't miss out on important work hours.
                                    </div>
                                    <div className="flex items-center"></div>
                                    <div className="w-10 h-10 mr-4">
                                        <img className="w-full h-full" src={sitejabberIcon} />
                                    </div>
                                    <div>
                                        <div className="text-neutral-700 font-bold">User ID 12241</div>
                                        <div className="text-neutral-400 flex items-center gap-x-1 flex-wrap">
                                            Order 52221XXXX | USA | Aug 23, 2023
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </SplideSlide>

                        <SplideSlide>
                            <li className="splide__slide splide__slide--clone" id="additional-review-slider-clone06" role="tabpanel" aria-roledescription="slide" aria-label="2 of 3" aria-hidden="true">
                                <div className="bg-white py-8 px-6 border-2 border-slate-300 border-solid rounded-2xl min-w-[300px] mx-1">
                                    <div className="flex items-center justify-between mb-5">
                                        <div>
                                            <img className="h-5" src={sitejabberStars} />
                                        </div>
                                        <div className="text-blue-900 text-xs flex items-center">Verified site experience</div>
                                    </div>
                                    <div className="text-neutral-700 text-xl font-bold mb-3">It was extremely well written.</div>
                                    <div className="text-neutral-700 mb-5">
                                        They assisted me when I got stuck after writing the first paragraph of my essay. Unsure of how to proceed, I turned to Google for help. I’m thrilled with my decision to use this company—they truly excelled! They continued working until my essay was perfected.
                                    </div>
                                    <div className="flex items-center"></div>
                                    <div className="w-10 h-10 mr-4">
                                        <img className="w-full h-full" src={sitejabberIcon} />
                                    </div>
                                    <div>
                                        <div className="text-neutral-700 font-bold">User ID 22344</div>
                                        <div className="text-neutral-400 flex items-center gap-x-1 flex-wrap">
                                            Order 52221XXXX | USA | Jul 22, 2024
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </SplideSlide>

                        <SplideSlide>
                            <li className="splide__slide splide__slide--clone" id="additional-review-slider-clone07" role="tabpanel" aria-roledescription="slide" aria-label="3 of 3" aria-hidden="true">
                                <div className="bg-white py-8 px-6 border-2 border-slate-300 border-solid rounded-2xl min-w-[300px] mx-1">
                                    <div className="flex items-center justify-between mb-5">
                                        <div>
                                            <img className="h-5" src={sitejabberStars} />
                                        </div>
                                        <div className="text-blue-900 text-xs flex items-center">Verified site experience</div>
                                    </div>
                                    <div className="text-neutral-700 text-xl font-bold mb-3">Overall, excellent!</div>
                                    <div className="text-neutral-700 mb-5">
                                        A huge thank you to the team at EduProsOnline, especially to the fantastic manager who excels at her job. I recommend this site to everyone. The paper was delivered with high quality and even two days ahead of schedule. It’s truly an outstanding service. Highly recommended! Thank you so much!
                                    </div>
                                    <div className="flex items-center">
                                        <div className="w-10 h-10 mr-4">
                                            <img className="w-full h-full" src={sitejabberIcon} />
                                        </div>
                                        <div>
                                            <div className="text-neutral-700 font-bold">User 33324</div>
                                            <div className="text-neutral-400 flex items-center gap-x-1 flex-wrap">
                                                Order 52221XXXX | USA | Sep 10,2024
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </li>
                        </SplideSlide>

                        <SplideSlide>
                            <li className="splide__slide splide__slide--clone is-active" id="additional-review-slider-clone08" role="tabpanel" aria-roledescription="slide" aria-label="1 of 3" aria-hidden="true">
                                <div className="bg-white py-8 px-6 border-2 border-slate-300 border-solid rounded-2xl min-w-[300px] mx-1">
                                    <div className="flex items-center justify-between mb-5">
                                        <div>
                                            <img className="h-5" src={sitejabberStars} />
                                        </div>
                                        <div className="text-blue-900 text-xs flex items-center">Verified site experience</div>
                                    </div>
                                    <div className="text-neutral-700 text-xl font-bold mb-3">EduProsOnline is the ideal solution.</div>
                                    <div className="text-neutral-700 mb-5">
                                        EduProsOnline is the perfect solution for academic assistance, helping you save valuable time to focus on other tasks, such as schoolwork or ensuring you don't miss out on important work hours.
                                    </div>
                                    <div className="flex items-center"></div>
                                    <div className="w-10 h-10 mr-4">
                                        <img className="w-full h-full" src={sitejabberIcon} />
                                    </div>
                                    <div>
                                        <div className="text-neutral-700 font-bold">User ID 12241</div>
                                        <div className="text-neutral-400 flex items-center gap-x-1 flex-wrap">
                                            Order 52221XXXX | USA | Aug 23, 2023
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </SplideSlide>
                    </Splide>
                </div>
            </section>

            <section className="text-gray-700 text-base py-20" id="faqs">
                <div className="px-4 max-w-screen-xl mx-auto text-neutral-700 lg:max-w-screen-lg md:max-w-screen-md sm:max-w-screen-sm">
                    <div>
                        <h3 className="text-4xl font-bold pb-16 text-center sm:pb-10" id="h3-1">
                            Essay Writing Service FAQs
                        </h3>

                        <div className="flex flex-col md:flex-row md:space-x-6 space-y-6 md:space-y-0">
                            <div className="flex-1 space-y-6">
                                <div className="bg-slate-50 p-6 rounded-2xl" onClick={() => setFaq(1)}>
                                    <div className="text-xl font-medium cursor-pointer toggle-question">
                                        Is EduProsOnline a Reliable Option?
                                    </div>
                                    {
                                        faq == 1 &&
                                        <div className="text-gray-600 mt-2">
                                            Our goal is to deliver services that meet the highest quality standards. At EduProsOnline, we offer unlimited free revisions and guarantee 100% satisfaction. We are a dependable company with 24/7 customer support and a full refund guarantee. Upon request, our team can provide a professional plagiarism report powered by Turnitin. If you have any concerns or face any issues, don’t hesitate to contact our support team immediately.
                                        </div>
                                    }
                                </div>

                                <div className="bg-slate-50 p-6 rounded-2xl" onClick={() => setFaq(2)}>
                                    <div className="text-xl font-medium cursor-pointer toggle-question">
                                        Is EduProsOnline a Legit Service?
                                    </div>
                                    {
                                        faq == 2 &&
                                        <div className="text-gray-600 mt-2">
                                            At our company, we meticulously adhere to some of the highest ethical standards and have made customer confidence our top priority. This is why thousands of customers have already entrusted us with their high-priority assignments, and we have consistently met their needs while adhering closely to all specified requirements and preferences. Our commitment to transparency, quality, and reliability has earned us a strong reputation in the academic writing industry. Rest assured, when you choose EduProsOnline, you are selecting a legit academic service.
                                        </div>
                                    }
                                </div>

                                <div className="bg-slate-50 p-6 rounded-2xl" onClick={() => setFaq(3)}>
                                    <div className="text-xl font-medium cursor-pointer toggle-question">
                                        Who Will Be Assigned to Work on My Order?
                                    </div>
                                    {
                                        faq == 3 &&
                                        <div className="text-gray-600 mt-2">
                                            We have a team of hundreds of skilled writers who are highly motivated to tackle even the most challenging academic tasks and deliver outstanding results. Our internal writer rating system boosts productivity and allows clients to track the progress of their tasks in near real-time with regular status updates. Once you place your order, our support team will select the most suitable writer, and work on your assignment will begin promptly.
                                        </div>
                                    }
                                </div>

                                <div className="bg-slate-50 p-6 rounded-2xl" onClick={() => setFaq(4)}>
                                    <div className="text-xl font-medium cursor-pointer toggle-question">
                                        When Do I Need to Pay for My Essay?
                                    </div>
                                    {
                                        faq == 4 &&
                                        <div className="text-gray-600 mt-2">
                                            Our payment process is clear and client-focused. When you place your order, you will be required to make a deposit on our website. However, the funds will only be released once you have received and approved your completed order.
                                        </div>
                                    }
                                </div>

                                <div className="bg-slate-50 p-6 rounded-2xl" onClick={() => setFaq(5)}>
                                    <div className="text-xl font-medium cursor-pointer toggle-question">
                                        Can EduProsOnline Be Trusted?
                                    </div>
                                    {
                                        faq == 5 &&
                                        <div className="text-gray-600 mt-2">
                                            You can trust us with your most challenging assignments, and we’ll consistently strive to meet and surpass your expectations. Our clients regularly provide us with positive and constructive feedback, which we thoroughly review, address, and incorporate. With our exceptional team of expert writers, we handle assignments across a wide range of academic disciplines and adhere to even the tightest deadlines. At EduProsOnline, we embody the trust of our customers and work diligently every day to uphold our reputation.
                                        </div>
                                    }
                                </div>

                                <div className="bg-slate-50 p-6 rounded-2xl" onClick={() => setFaq(6)}>
                                    <div className="text-xl font-medium cursor-pointer toggle-question">
                                        How Quickly Can EduProsOnline Deliver My Essay?
                                    </div>
                                    {
                                        faq == 6 &&
                                        <div className="text-gray-600 mt-2">
                                            The standard deadline for completing orders is 3-5 days. However, we strive to deliver your completed order as quickly as possible, often a day or even a few hours before the agreed deadline. In urgent cases, we can deliver your paper in as little as 1-3 hours! Be sure to discuss your preferred deadline with our support team. Please note that some orders, especially advanced college-level papers, may require up to 30 days for delivery.
                                        </div>
                                    }
                                </div>

                            </div>
                            <div className="flex-1 space-y-6">
                                <div className="bg-slate-50 p-6 rounded-2xl" onClick={() => setFaq(7)}>
                                    <div className="text-xl font-medium cursor-pointer toggle-question">
                                        Is EduProsOnline Worth the Investment?
                                    </div>
                                    {
                                        faq == 7 &&
                                        <div className="text-gray-600 mt-2">
                                            In addition to maintaining the highest quality standards, we strive to offer our customers affordable pricing. When you place your first order with EduProsOnline, you’ll receive a 15% one-time discount. To promote long-term collaboration, we have a progressive discount system that starts saving you money from your 11th order onwards. This advanced incentive system, combined with the professionalism of our writers and support team, will significantly enhance your experience with us. If you have any issues or need clarification about our services or your specific order, don’t hesitate to reach out to our support team.
                                        </div>
                                    }
                                </div>

                                <div className="bg-slate-50 p-6 rounded-2xl" onClick={() => setFaq(8)}>
                                    <div className="text-xl font-medium cursor-pointer toggle-question">
                                        How Will I Be Notified When My Order Is Completed?
                                    </div>
                                    {
                                        faq == 8 &&
                                        <div className="text-gray-600 mt-2">
                                            On your Personal Account page, you can track the progress of your order. If needed, you can also request an interim status update from our support team. Once your order is completed, it will be sent to you as an email attachment. We recommend keeping your contact information up-to-date and checking your email regularly.
                                        </div>
                                    }
                                </div>

                                <div className="bg-slate-50 p-6 rounded-2xl" onClick={() => setFaq(9)}>
                                    <div className="text-xl font-medium cursor-pointer toggle-question">
                                        Does EduProsOnline Guarantee Plagiarism-Free Work?
                                    </div>
                                    {
                                        faq == 9 &&
                                        <div className="text-gray-600 mt-2">
                                            Yes, we uphold the highest professional and ethical standards, ensuring that all papers from EduProsOnline are completely free from plagiarism. Our dedicated writers create 100% original content for every order and perform rigorous plagiarism checks before final delivery. You can trust us to provide reliable content that meets your expectations and maintains your confidence in our services.
                                        </div>
                                    }
                                </div>

                                <div className="bg-slate-50 p-6 rounded-2xl" onClick={() => setFaq(10)}>
                                    <div className="text-xl font-medium cursor-pointer toggle-question">
                                        Is EduProsOnline a Secure Platform?
                                    </div>
                                    {
                                        faq == 10 &&
                                        <div className="text-gray-600 mt-2">
                                            With over a decade of experience, we have successfully completed more than a million orders. Our clients frequently recommend EduProsOnline to their friends and family as a trustworthy company capable of delivering a broad range of high-quality services. We cherish our positive reputation and the trust of our customers, working diligently each day to meet our professional commitments. To enhance customer convenience, we offer a 24/7 support hotline to address questions and provide assistance at any stage of our service.
                                        </div>
                                    }
                                </div>

                                <div className="bg-slate-50 p-6 rounded-2xl" onClick={() => setFaq(11)}>
                                    <div className="text-xl font-medium cursor-pointer toggle-question">
                                        Is EduProsOnline a Quality Writing Service?
                                    </div>
                                    {
                                        faq == 11 &&
                                        <div className="text-gray-600 mt-2">
                                            At our company, we strive to balance quality and affordability. We have recruited top academic writers and frequently offer attractive discounts and promotions to our customers. When you choose us for your assignment, you can be confident you'll receive the highest quality at the best price. At EduProsOnline, we prioritize our customers' needs over financial gains, reflecting our commitment to excellence not just in the quality of our work, but also in maintaining the highest legal and ethical standards.
                                        </div>
                                    }
                                </div>

                                <div className="bg-slate-50 p-6 rounded-2xl" onClick={() => setFaq(12)}>
                                    <div className="text-xl font-medium cursor-pointer toggle-question">
                                        Does EduProsOnline Provide Good Customer Support?
                                    </div>
                                    {
                                        faq == 12 &&
                                        <div className="text-gray-600 mt-2">
                                            Our company was one of the pioneers in offering 24/7 customer support. With our comprehensive support, EduProsOnline ensures a smooth and satisfactory experience from your initial inquiry through to the final delivery of services. We employ only native English-speaking agents who are always available to answer your questions, provide quick quotes, and offer status updates on your order upon request. They are dedicated to resolving any issues, addressing complaints, and professionally handling constructive feedback.
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
}

export default Home;