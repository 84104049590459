import React, { useState } from "react";
import Header from "./components/header";
import Home from "./pages/Home";
import Router from "./router/router";

function App() {
  const [toggleLoginOrderForm, setToggleLoginOrderForm] = useState(1);

  return (
    <React.Fragment>
      <Router />
      {/* <Header setToggleLoginOrderForm={setToggleLoginOrderForm} /> */}
    </React.Fragment>
  )
}

export default App;
