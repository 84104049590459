import footerBadge1 from '../images/footer-badge-color-1.png';
import footerBadge2 from '../images/footer-badge-color-2.png';
import footerBadge3 from '../images/footer-badge-color-3.png';
import moneyBack from '../images/footer-moneyback.png';
import footerPayments from '../images/footer-payments.svg';

function Footer() {
    return (
        <footer className="text-gray-700 text-base py-12 bg-indigo-800 border border-black">
        <div className="px-4 max-w-7xl mx-auto">
          <div className="flex flex-wrap md:justify-between">
            <div className="w-full md:max-w-3xl">
              <div className="mb-6 text-indigo-400">
              </div>

              <div className="text-indigo-400 text-xs mb-2">
                © 2024 . All Rights Reserved
              </div>

              <div className="text-indigo-400 text-xs mb-12 max-w-[500px]">
                <span className="font-bold">Disclaimer:</span> The products and services on this website are meant for research and guidance purposes only. Students are responsible for their own work and should use the materials provided solely as references.
              </div>

              <div className="flex gap-3 mb-4">
                <img src={footerBadge1} className="w-16 h-8" />
                <a className="text-blue-600">
                  <img src={footerBadge2} className="w-16 h-8 cursor-pointer" />
                </a>
                <img src={footerBadge3} className="w-16 h-8" />
              </div>

              <div className="flex gap-4 items-center">
                <img src={moneyBack} className="w-14 h-14" />
                <div className="text-indigo-400 text-xs">
                  * the money back guarantee operates according to our Refund
                  Policy
                </div>
              </div>
            </div>

            <div className="w-full md:w-auto md:max-w-xs mb-10">
              <ul className="flex flex-col gap-y-6 text-white">
                <li className="text-lg font-medium text-white">Company</li>
                <li>
                  <a href="#how-it-works" className='text-white td-none'>How it workss</a>
                </li>
                <li><a href="#pricing" className='text-white td-none'>Pricing</a></li>
                <li>
                  <a href="#review" className='text-white td-none'>Customer Reviews</a>
                </li>
                <li><a href="#faqs" className='text-white td-none'>FAQs</a></li>
              </ul>
            </div>


            <div className="w-full md:w-auto">
              <div className="flex flex-col">
                <div className="text-white text-lg font-medium mb-4">Contacts</div>
                <a className="text-white mb-4 td-none">+1.866.504.4611</a>
                <a href="mailto:care@eduprosonline.com" className="text-white mb-12 td-none">care@eduprosonline.com</a>

                <div className="text-white text-lg font-medium mb-4">We accept</div>
                <img src={footerPayments} className="w-40 h-6" />
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
}

export default Footer;